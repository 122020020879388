export const WWW_URL = "https://www.petal.org";
export const SUPPORT_URL = {
  type: "email",
  link: "mailto:help@petal.org",
};

export const PROD_HOSTNAME = "cite.petal.org";
export const DEV_HOSTNAME = "cite.petal-dev.org";

export const PROD_URL = `https://${PROD_HOSTNAME}`;
export const DEV_URL = `https://${DEV_HOSTNAME}`;

export const LOGO_SRC = "/img/logo/petal-logo.png";
export const SMALL_LOGO_SRC = "/img/logo/petal-logo-small.png";

export const APP_TITLE = "Petal";

export const companyFeatures = {
  isWhiteLabel: false,
  enablePetalRoutes: true,
  enableSecondaryApps: true,
  workspace: {
    singleWorkspace: false,
  },
  auth: {
    enableSignUp: true,
    enableGoogleAuth: true,
    enableForgotPassword: true,
  },
  settings: {
    enableSettingsPage: true,
    canUpgradePlans: true,
    canLeaveWorkspace: true,
  },
  app: {
    canSeeOnboarding: true,
    isTrialExists: true,
    canInvite: true,
    canCreateOrg: true,
    showDocumentation: true,
    guests: {
      canSeeNotifications: true,
      enableDocumentViewer: true,
      enableDocumentActions: true,
      enableLookUpTab: true,
    },
  },
};

export const companySettings: {
  defaultAIModel: "gpt-3.5-turbo" | "gpt-4";
  defaultMultiDocChatModel: "gpt-3.5-turbo-16k" | "gpt-4";
} = {
  defaultAIModel: "gpt-3.5-turbo",
  defaultMultiDocChatModel: "gpt-3.5-turbo-16k",
};
