/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import aiService, { useAITables, useTableColumns } from "api/aiService";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AILanguage, AILanguages } from "utils/aiHelpers";
import { LoadingButton } from "@mui/lab";
import { AITable } from "models/api/response.types";
import { PROD_HOSTNAME, companySettings } from "company-config";

const AiTableColumnDialog: React.FC<{
  table: AITable;
  open: boolean;
  columnId?: number;
  setOpen: (open: boolean) => void;
}> = ({ table, columnId, open, setOpen }) => {
  const { updateAITableMutation } = useAITables(table.organization_id);
  const { columns, upsertCachedColumn } = useTableColumns(table.id);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [name, setName] = useState("");
  const [language, setLanguage] = useState<AILanguage>("English (US)");
  const isProd = window.location.hostname === PROD_HOSTNAME;
  const [aiModel, setAiModel] = useState<"gpt-3.5-turbo" | "gpt-4">(
    companySettings.defaultAIModel
  );

  useEffect(() => {
    setQuery("");
    if (columns && columnId) {
      const column = columns.find((c) => c.id === columnId);
      if (column) {
        setName(column.name);
        setQuery(column.query);
        setAiModel(column?.meta?.model || "gpt-3.5-turbo");
        setLanguage(
          column?.meta.language
            ? (column.meta.language as AILanguage)
            : "English (US)"
        );
      }
    }
  }, [columns, columnId]);

  const onClose = () => {
    setOpen(false);
    setQuery("");
    setLanguage("English (US)");
  };

  const handleCreate = () => {
    setLoading(true);
    aiService
      .createTableColumn(table.id, {
        query,
        name,
        order_id: (columns?.length || 0) + 1,
        meta: {
          language,
          model: aiModel,
        },
      })
      .then((response) => {
        upsertCachedColumn(response.data);
        onClose();
        updateAITableMutation.mutate({
          tableId: table.id,
          body: {
            meta: {
              ...table.meta,
              last_edited: new Date().toISOString(),
            },
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    if (columnId) {
      setLoading(true);
      aiService
        .updateTableColumn(table.id, columnId, {
          query,
          name,
          meta: {
            model: aiModel,
            language,
          },
        })
        .then((response) => {
          upsertCachedColumn(response.data);
          setOpen(false);
          updateAITableMutation.mutate({
            tableId: table.id,
            body: {
              meta: {
                ...table.meta,
                last_edited: new Date().toISOString(),
              },
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && query.length === 0) {
      e.preventDefault();
      if (columnId) {
        handleUpdate();
      } else {
        handleCreate();
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {columnId ? "Edit question" : "Add question"}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Select
          value={language}
          size="small"
          variant="outlined"
          fullWidth
          onChange={(e) => setLanguage(e.target.value as AILanguage)}
        >
          {AILanguages.map((lang) => (
            <MenuItem key={lang} value={lang}>
              <Typography variant="body2">{lang}</Typography>
            </MenuItem>
          ))}
        </Select>
        <FormControl
          variant="standard"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              color: "#272727",
              position: "relative",
              fontSize: "14px",
              transform: "none",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
          }}
        >
          <InputLabel shrink htmlFor="column-title">
            Column title
            <Tooltip
              enterDelay={500}
              placement="top"
              title="Title is optional. If you don't enter a title, the default title will be your AI Question"
            >
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </Tooltip>
          </InputLabel>
          <TextField
            id="column-title"
            variant="outlined"
            color="primary"
            value={name}
            size="small"
            placeholder="Type column title"
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        {!isProd && (
          <FormControl
            variant="standard"
            fullWidth
            sx={{
              "& .MuiInputLabel-root": {
                color: "#272727",
                position: "relative",
                fontSize: "14px",
                transform: "none",
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
                gap: 1,
              },
            }}
          >
            <InputLabel shrink>AI Model</InputLabel>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                width: "100%",
              }}
            >
              <Typography className="body2" color="textSecondary">
                GPT-3.5
              </Typography>
              <Switch
                color="primary"
                size="small"
                checked={aiModel === "gpt-4"}
                onChange={() => {
                  setAiModel(aiModel === "gpt-4" ? "gpt-3.5-turbo" : "gpt-4");
                }}
              />
              <Typography className="body2" color="textSecondary">
                GPT-4
              </Typography>
            </Box>
          </FormControl>
        )}
        <FormControl
          variant="standard"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              color: "#272727",
              position: "relative",
              fontSize: "14px",
              transform: "none",
              marginBottom: "8px",
            },
          }}
        >
          <InputLabel shrink htmlFor="ai-question">
            AI Question
          </InputLabel>
          <TextField
            id="ai-question"
            variant="outlined"
            color="primary"
            value={query}
            size="small"
            placeholder="Type your question"
            fullWidth
            multiline
            minRows={3}
            onKeyDown={handleKeyPress}
            onChange={(e) => setQuery(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          disabled={query.length === 0 || loading}
          size="medium"
          color="primary"
          loading={loading}
          onClick={() => {
            if (columnId) {
              handleUpdate();
            } else {
              handleCreate();
            }
          }}
        >
          {columnId ? "Edit" : "Add"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AiTableColumnDialog;
